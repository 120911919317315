.md-menu {
  margin-right: 15px;
  cursor: pointer;
}
.md-menu:hover {
  background-color: #dae9f7;
}
.row-width {
  width: 98.5%;
}
.el-card__header {
  vertical-align: middle;
}
.el-table th {
  padding: 0 ;
}
.el-table thead {
  height: 15px;
  color: #0b0b3b;
  font-size: 12px;
  background: rebeccapurple;
}
.el-table td {
  padding: 5px ;
  font-size: 11px;
}
