































































































































































































































































































































































































































































































































































































































































































































































.el-table {
    th {
        padding: 0 ;
    }
    thead{
        height: 15px;
        color:rgb(11, 11, 59);
        font-size:12px;
        background:rebeccapurple;
    }
    td {
        padding: 5px ;
        font-size:11px;
    } 
}
.el-dialog__body{
    padding: 10px;
}
.el-dialog__header{
    padding: 0px;
}
.el-dialog__footer{
    padding: 5px;
    border-top:1px solid #ccc;
} 
.el-dialog__headerbtn{font-size: 20px; background:white;top:10px !important;}

.el-table .success-row {
    color:#959997;
}
.el-table .cancel-row {
    color: #e00e66;
}
.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after{
    border-color :#3d77eb;
}
.el-checkbox__input.is-disabled+span.el-checkbox__label{
    color:#3d77eb
}
