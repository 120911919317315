












































































































































































































































































































































.md-menu{margin-right: 15px;cursor: pointer}
.md-menu:hover{background-color:#dae9f7 }

.row-width  {
    width: 98.5%;
}
.el-card__header{
    //height:35px;
    vertical-align: middle;
}
.el-table {
    th {
        padding: 0 ;
    }
    thead{
        height: 15px;
        color:rgb(11, 11, 59);
        font-size:12px;
        background:rebeccapurple;
    }
    td {
        padding: 5px ;
        font-size:11px;
    } 
}

