





















































































































































































































































































































.el-table {
    th {
        padding: 0 ;
    }
    thead{
        height: 15px;
        color:rgb(11, 11, 59);
        font-size:12px;
        background:rebeccapurple;
    }
    td {
        padding: 5px ;
        font-size:11px;
    } 
}
.el-dialog__body{
    padding: 10px;
}
.el-dialog__header{
    padding: 0px;
}
.el-dialog__footer{
    padding: 5px;
    border-top:1px solid #ccc;
} 
.el-dialog__headerbtn{font-size: 20px; background:white;top:5px !important;}
//.el-dialog__headerbtn i { background:white;font-size: 20px;  }
.el-table .success-row {
    color:#959997;
}
.el-table .cancel-row {
    color: #e00e66;
}
